// Copyright © 2019 Mapotempo

// Turbolinks hack to display notify on previous page
// $(document).on('page:restore', function () {
//   PNotify.prototype.options.stack.context = $('body');
// });

export default () => {
  PNotify.defaults.styling = 'bootstrap3'; // Bootstrap version 3
  PNotify.defaults.icons = 'fontawesome4'; // glyphicons
  PNotify.defaults.modules.Buttons = {
    sticker: false,
    closer: true,
    labels: { close: I18n.t('web.dialog.close') }
  };
  PNotify.defaults.stack.context = document.body;

  const isWindowVisible = (function() {
    var stateKey, eventKey, keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange'
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    return function (callback) {
      if (callback) document.addEventListener(eventKey, callback);
      return !document[stateKey];
    };
  })();

  const generateNotification =
  (status) =>
    (message, options) => {
      var notification = function() {
        PNotify.alert({
          text: message,
          type: status,
          animation: 'slide',
          animate_speed: 'slow',
          shadow: true,
          hide: true,
          ...options
        });
      };

      var notifyDisplayed = false;
      if (isWindowVisible()) {
        notification();
      } else {
        isWindowVisible(function () {
          if (isWindowVisible()) {
            if (!notifyDisplayed) {
              notification();
            }
            notifyDisplayed = true;
          }
        });
      }
    };

  const notifySuccess = generateNotification('success');
  const notifyWarning = generateNotification('notice');
  const notifyError = generateNotification('error');

  $.extend(window, {
    warning: notifyWarning,
    error: notifyError,
    notice: notifySuccess,
    notify: (status, message, options) => generateNotification(status)(message, options),
    stickyNotice: (message, options) => notifySuccess(message, { ...options, hide: false }),
    stickyError: (message, options) => notifyError(message, {...options, hide: false }),
    hideNotices: () => PNotify.removeAll(),
  });
};

// function desktop_notify(status, message, options) {
//   PNotify.desktop.permission();
//   notify(status, message, $.extend({
//     title: resellerName,
//     desktop: {
//       desktop: true
//     }
//   }, options));
// }
