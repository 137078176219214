// Copyright © 2019-2021 Mapotempo

Number.prototype.toHHMM = function() {
  var sec_num = parseInt(this, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes;
};

Date.prototype.toLocalISOString = function() {
  // ISO 8601
  var d = this
    , pad = function(n) { return n < 10 ? '0' + n : n; }
    , tz = d.getTimezoneOffset() // mins
    , tzs = (tz > 0 ? "-" : "+") + pad(parseInt(Math.abs(tz / 60)));

  if (tz % 60 != 0)
    tzs += pad(Math.abs(tz % 60));

  if (tz === 0) // Zulu time == UTC
    tzs = 'Z';

  return d.getFullYear() + '-'
    + pad(d.getMonth() + 1) + '-'
    + pad(d.getDate()) + 'T'
    + pad(d.getHours()) + ':'
    + pad(d.getMinutes()) + ':'
    + pad(d.getSeconds()) + tzs;
};

function decimalAdjust(type, value, exp) {

  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

if (!Math.round10) {
  Math.round10 = function(value, exp) {
    return decimalAdjust('round', value, exp);
  };
}

if (!Math.floor10) {
  Math.floor10 = function(value, exp) {
    return decimalAdjust('floor', value, exp);
  };
}

if (!Math.ceil10) {
  Math.ceil10 = function(value, exp) {
    return decimalAdjust('ceil', value, exp);
  };
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

Array.prototype.intersect = function(arr) {
  return this.filter(value => arr.includes(value));
};

// JQUERY UI 1.12 slow down sortable perf so we have to override this method in order to re-use 1.11 code
// https://bugs.jqueryui.com/ticket/15097
$.ui.sortable.prototype._setHandleClassName =  function() {
  var that = this;
  this._removeClass(this.element.find(".ui-sortable-handle"), "ui-sortable-handle");
  $.each(this.items, function() {
    (this.instance.options.handle
      ? this.item.find(this.instance.options.handle)
      : this.item
    ).addClass('ui-sortable-handle');
  });
};
