// Copyright © 2017 Mapotempo

(function (root, factory) {
  if (typeof exports === 'object') {
    // CommonJS
    module.exports = factory();
  } else if (typeof define === 'function' && define.amd) {
    // AMD
    define([], function () {
      return (root.returnExportsGlobal = factory());
    });
  } else {
    // Global Variables
    root.returnExportsGlobal = factory();
  }
}(this, function () {
  'use strict';

  var logEl,
    isInitialized = false;

  function createElement(tag, css) {
    var element = document.createElement(tag);
    element.style.cssText = css;
    return element;
  }

  function createPanel(options) {
    if (!isInitialized) {
      throw 'You need to call `screenLog.init()` first.';
    }
    options = options || {};
    options.bgColor = options.bgColor || 'black';
    options.color = options.color || 'lightgreen';
    options.css = options.css || '';
    var div = createElement('div',
      'display:none;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;padding:8px;text-align:left;opacity:0.7;position:fixed;right:0;bottom:0;min-width:260px;max-height:50vh;overflow:auto;z-index:9999;background:'
      + options.bgColor + ';color:'
      + options.color + ';'
      + options.css);
    return div;
  }

  function log(data, colorStyle) {
    if (!isInitialized) {
      throw 'You need to call `screenLog.init()` first.';
    }
    var el = createElement('div', 'line-height:18px;background:' +
      (logEl.children.length % 2 ? 'rgba(255,255,255,0.1);' : 'inherit;') +
      (colorStyle ? 'color:' + colorStyle : '')); // zebra lines
    //el.textContent = [].slice.call(arguments).reduce(function(prev, arg) {
    //    return prev + ' ' + arg;
    //}, '');

    el.textContent = data;

    // If any elements, show log element
    logEl.style.removeProperty('display');


    logEl.appendChild(el);
    // Scroll to last element
    logEl.scrollTop = logEl.scrollHeight - logEl.clientHeight;
  }

  function clear() {
    if (!isInitialized) {
      throw 'You need to call `screenLog.init()` first.';
    }
    logEl.innerHTML = '';
  }

  function init(options) {
    isInitialized = true;
    options = options || {};
    logEl = createPanel(options);
    document.body.appendChild(logEl);
  }

  window.ScreenLog = {
    init: init,
    log: log,
    clear: clear
  };

  
}));
